interface HostSet {
  host_lbapi: string | undefined;
  host_felicity: string | undefined;
  host_fiddledead: string | undefined;
  host_sso: string | undefined;
  host_staghorn: string | undefined;
  host_express: string | undefined;
  host_unity: string | undefined;
  new_relic_api_key: string | undefined;
  new_relic_app_name: string | undefined;
  host_my_change_logs: string | undefined;
  pubnub_subscribe_key: string | undefined;
  pubnub_publish_key: string | undefined;
  engage_page_url: string | undefined;
  launch_darkly_client_key: string | undefined;
  application_client_id: string | undefined;
  timeout: string | undefined;
}

interface Config extends HostSet {
  ga_key: string | undefined;

  show_lblite_login: string | undefined;

  getURL: (urlName: keyof HostSet) => string | undefined;

  getClientId: () => string;
}

const ConfigHelper: Config = {
  show_lblite_login: (window as any).env.SHOW_LBLITE_LOGIN,

  ga_key: (window as any).env.GOOGLE_ANALYTICS_KEY,
  new_relic_api_key: (window as any).env.NEW_RELIC_API_KEY,
  new_relic_app_name: (window as any).env.NEW_RELIC_APP_NAME,

  host_express: (window as any).env.EXPRESS_SERVER_HOST,
  host_lbapi: (window as any).env.LBAPI_HOST,
  host_felicity: (window as any).env.FELICITY_HOST,
  host_fiddledead: (window as any).env.FIDDLEDEAD_HOST,
  host_sso: (window as any).env.SSO_HOST,
  host_staghorn: (window as any).env.STAGHORN_HOST,
  host_unity: (window as any).env.UNITY_HOST,
  host_my_change_logs: (window as any).env.MY_CHANGE_LOGS_HOST,
  pubnub_publish_key: (window as any).env.PUBNUB_PUBLISH_KEY,
  pubnub_subscribe_key: (window as any).env.PUBNUB_SUBSCRIBE_KEY,
  engage_page_url: (window as any).env.LB_ENGAGE_PAGE_URL,
  launch_darkly_client_key: (window as any).env.LAUNCH_DARKLY_CLIENT_KEY,
  application_client_id: (window as any).env.APPLICATION_CLIENT_ID,
  timeout: (window as any).env.TIMEOUT,

  getURL(urlName) {
    return this[urlName];
  },

  getClientId() {
    return "1bc4e40b-0373-42a8-bfed-979f10b0743a";
  },
};

export default ConfigHelper;
